import React from 'react';

export interface FormProps {
  text: {
    title: string,
    description: string,
    buttonValue: string
  },
  isOnprem: boolean,
  isMessage: boolean
}

const FormComponent = ({ text, isOnprem, isMessage }: FormProps) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    const {
      elements: {
        email: { value: email },
        phone: { value: phone },
        name: { value: name },
        company: { value: company },
        onprem: { value: onprem } = { value: 'off' },
        message: { value: message } = { value: '' },
      },
    } = event.target;

    const data = {
      email,
      phone,
      name,
      company,
      onprem: onprem === 'on',
      message,
      is_trial_request: false,
      formName: 'requestDemo',
    };

    window.lr_analytics.identify(data.email, data);
    window.lr_analytics.track('filled-form', data);
    window.lr_analytics.mntnConversion();
    window._vis_opt_queue = window._vis_opt_queue || [];
    window._vis_opt_queue.push(() => { window._vis_opt_goal_conversion(213); });
    if (window.lintrk) {
      window.lintrk('track', { conversion_id: 18050905 });
    }

    const submitElement = document.querySelector('#request-demo-form button');
    const submit = submitElement as HTMLButtonElement;

    submit.disabled = true;
    submit.value = 'Sending...';

    const onSuccess = () => {
      document.querySelector('#request-demo-submitted').classList.remove('hidden');
      document.querySelector('#request-demo-form').classList.add('hidden');
    };

    const onClose = () => {
      const thankyouElement = document.querySelector('#request-demo-form button') as HTMLHeadElement;
      thankyouElement.innerText = "Couldn't Find a Time? No worries.";
      onSuccess();
    };

    const sendHook = () => window.fetch('https://hooks.zapier.com/hooks/catch/3092116/gcbsyi/', {
      method: 'POST',
      body: JSON.stringify(data),
    });

    const captureException = (err) => window.LogRocket && window.LogRocket.captureException(err);

    const options = {
      onSuccess,
      onClose,
      onError: onSuccess,
    };

    if (window.ChiliPiper && window.sixDomain) {
      window.ChiliPiper.submit('logrocket', 'Inbound_Router_Web', options);
      sendHook().catch(captureException);
    } else {
      sendHook().then(onSuccess).catch((err) => {
        document.querySelector('#request-demo-submitted').classList.remove('hidden');
        document.querySelector('#request-demo-form').classList.add('hidden');
        captureException(err);
      });
    }


    event.preventDefault();
    return false;
  }

  return <section className='form-section'>
    <div className='container'>
      <div>
        <h1 className='mb-5'>{text.title}</h1>
        <h5 className='mb-5'>{text.description}</h5>
      </div>
      <div className='form-conatiner'>
        <form id='request-demo-form' className='mx-3 mx-lg-5 my-4' onSubmit={handleSubmit}>
          <div className='form-row'>
            <div className='form-group col-md-6'>
              <label htmlFor='inputEmail4' className='w-100'>Your Work Email
                <input type='email' className='form-control mt-2' id='inputEmail4' name='email' required />
              </label>
            </div>
            <div className='form-group col-md-6'>
              <label htmlFor='inputPhoneNumber4' className='w-100'>Your Phone Number
                <input className='form-control mt-2' id='inputPhoneNumber4' name='phone' type='tel' required />
              </label>
            </div>
          </div>
          <div className='form-row'>
            <div className='form-group col-md-6'>
              <label htmlFor='inputName4' className='w-100'>
                Your Name
                <input className='form-control mt-2' id='inputName4' name='name' type='text' required />
              </label>
            </div>
            <div className='form-group col-md-6'>
              <label htmlFor='inputCompanyName4' className='w-100'>
                Company Name
                <input className='form-control mt-2' id='inputCompanyName4' name='company' type='text' required />
              </label>
            </div>
          </div>
          {isOnprem && <div className='form-group mb-5 mt-2 row justify-content-center'>
            <div className='form-check'>
              <label className='form-check-label w-100' htmlFor='gridCheck'>
                <input className='form-check-input' type='checkbox' id='gridCheck' />
                <strong>I’m interested in running LogRocket on-premise</strong>
              </label>
            </div>
          </div>}
          {isMessage && <div className='mb-5 mx-2'>
            <label htmlFor='Textarea' className='w-100'>Message
              <textarea className='form-control mt-2' id='validationTextarea' name='message' required />
            </label>
          </div>}
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {text?.buttonValue && <button type='submit' value='submit' className='btn btn-secondary justify-content-center'>
              {text.buttonValue}
              <i className='arrow right-arrow' />
            </button>}
          </div>
        </form>
        <div className='hidden' id='request-demo-submitted'>
          <h3 id='thank-you-text'>Thank you!</h3>
          <p>We&apos;ll be in touch soon.</p>
        </div>
      </div>
    </div>
  </section>
};
export default FormComponent;
